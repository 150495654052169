import React from 'react';
import { Card } from '@mui/material';
import { styled } from '@mui/system';

const keyframes = `
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;

const GhostCardContainer = styled(Card)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '3rem auto',
  alignItems: 'center',
  gridColumnGap: '1rem', // Add space between columns
  minWidth: "100%",
  height: "6rem",
  marginBottom: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  animation: 'pulse 1.5s infinite',
}));

const GhostText = styled('div')({
  backgroundColor: '#e0e0e0',
  height: '1.5rem',
  marginBottom: '0.5rem',
  borderRadius: '4px',
  marginTop: '0.5rem'
});

const GhostSquare = styled('div')({
  backgroundColor: '#e0e0e0',
  width: '3rem',
  height: '3rem',
  borderRadius: '4px',
  marginLeft: '1rem',
  paddingRight: '1rem',
});

const GhostTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '1rem',
  paddingLeft: '1rem',
});


const GhostCard: React.FC = () => {
  return (
    <GhostCardContainer>
      <style>{keyframes}</style>
      <GhostSquare />
      <GhostTextContainer>
        <GhostText />
        <GhostText />
      </GhostTextContainer>
    </GhostCardContainer>
  );
};

export default GhostCard;