import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
} from "react";
import { ApiClient } from "../ApiClient";

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  profilePhoto: string;
};

export interface UserContextInterface {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

const defaultState = {} as UserContextInterface;

export const UserContext = createContext(defaultState);

type UserProviderProps = {
  children: ReactNode;
};

export default function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    ApiClient.getUser().then((user) => {
      console.log(user);
      setUser(user);
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
