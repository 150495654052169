import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../logo.svg";

const logoStyle = {
  width: "20vh",
  height: "auto",
  cursor: "pointer",
};

export default function NotFoundPage() {
  const defaultTheme = createTheme({ palette: { mode: "light" } });

  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={20}
      >
        <img src={logo} style={logoStyle} alt="Logo of Flight Finder" />
        <Typography variant="h1" component="div" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          Oops! We can&rsquo;t find the page you&rsquo;re looking for.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoHome}>
          Go Home
        </Button>
      </Box>
    </ThemeProvider>
  );
}
