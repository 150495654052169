import * as React from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import App from "../App";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import FlightsPage from "../pages/FlightsPage";
import NotFoundPage from "../pages/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";

const LandingPageWithFooter = () => (
  <>
    <LandingPage />
    <Divider />
    <Footer />
  </>
);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<LandingPageWithFooter />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="livesearch" element={<FlightsPage />} />
      <Route element={<PrivateRoute />}>
        {/* Add any routes that require authentication here */}
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);
