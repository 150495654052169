import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { UserContext } from "../contexts/UserContext";
import LandingNavBar from "../components/landing/LandingNavBar";
import FlightSearch from "../components/FlightSearch";
import Hero from "../components/landing/Hero";
import backgroundImage from '../assets/landing_page_background.jpg';
import { ApiClient } from "../ApiClient";
import { Airport } from "../ApiClient";
import { Dayjs } from "dayjs";

export default function LandingPage() {
  const { user } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (from: Airport | null, to: Airport | null, date: Dayjs | null) => {
    setIsLoading(true);
    try {
      if (from && to && date) {
        await ApiClient.getLiveSearchFlights(from.iataCode, to.iataCode, date.toDate());
        navigate("/livesearch", {
          state: {
            from,
            to,
            date
          }
        });
      } else {
        console.error("Missing search parameters");
        // Handle error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error fetching flights:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const defaultTheme = createTheme({ 
    palette: { mode: "light" },
    typography: {
      h1: {
        textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
      },
      subtitle1: {
        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        sx={{
          position: 'relative',
          minHeight: "100vh",
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center 20%",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            zIndex: 0,
            animation: 'gradientShift 35s ease infinite',
            '@keyframes gradientShift': {
              '0%': { backgroundPosition: '0% 15%' },
              '50%': { backgroundPosition: '100% 46%' },
              '100%': { backgroundPosition: '0% 15%' },
            },
          }}
        />

        {/* Blurred Overlay with Gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: 'blur(6px)',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.1))',
            zIndex: 1,
          }}
        />

        {/* Content */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <LandingNavBar />
          <Hero showWaitlistContent={!user} />
          {user && (
            <FlightSearch onSearch={handleSearch} isLoading={isLoading} />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
