import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import InlineFlightCard from '../components/flights/InlineFlightCard';
import { ApiClient, Flight, Airport } from '../ApiClient';
import LandingNavBar from "../components/landing/LandingNavBar";
import AirlineFlightCard from '../components/flights/AirlineFlightCard';
import FlightSearch from '../components/FlightSearch';
import { useLocation } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import LoadingSpinner from '../components/LoadingSpinner';
import GhostCard from '../components/flights/GhostCard';

const useFlights = () => {
  const [flights, setFlights] = useState<Flight[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // New state to track airlines we're waiting for and their timeouts
  const [pendingAirlines, setPendingAirlines] = useState<Record<string, boolean>>({});
  const [airlineTimeouts, setAirlineTimeouts] = useState<Record<string, NodeJS.Timeout>>({});

  const fetchFlights = useCallback(async (src: string, dest: string, date: Date) => {
    try {
      setLoading(true);
      setError(null);
      setFlights([]); // Clear previous flights
      setPendingAirlines({}); // Reset pending airlines
      setAirlineTimeouts({}); // Reset timeouts

      const fetchedFlights = await ApiClient.getLiveSearchFlights(src, dest, date);
      console.log('Fetched live search flights:', fetchedFlights);
      setFlights(fetchedFlights);

      // Initialize pending airlines based on known airlines
      const allAirlines = ['united', 'delta', 'american', 'alaska']; // Add your list of airlines here
      const initialPendingAirlines: Record<string, boolean> = {};
      allAirlines.forEach((airline) => {
        initialPendingAirlines[airline] = true;
      });
      setPendingAirlines(initialPendingAirlines);

      // Set timeouts for each airline
      allAirlines.forEach((airline) => {
        const timeout = setTimeout(() => {
          setPendingAirlines((prev) => ({
            ...prev,
            [airline]: false,
          }));
          setAirlineTimeouts((prev) => {
            const { [airline]: _, ...rest } = prev;
            return rest;
          });
        }, 20000); // 20 seconds timeout

        setAirlineTimeouts((prev) => ({
          ...prev,
          [airline]: timeout,
        }));
      });

      // Join the live search room after fetching initial flights
      ApiClient.joinLiveSearchRoom(src, dest, date);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    ApiClient.initializeSocket();

    ApiClient.subscribeToFlightUpdates((data: any) => {
      const updatedFlight = data.flight;
      console.log('Received new flight data:', data);

      setFlights((prevFlights) => {
        if (data.operation === 'INSERT') {
          console.log('Adding new flight:', updatedFlight);
          return [...prevFlights, updatedFlight];
        } else if (data.operation === 'UPDATE') {
          const index = prevFlights.findIndex((flight) => flight.id === updatedFlight.id);
          if (index !== -1) {
            console.log('Updating existing flight:', updatedFlight);
            const newFlights = [...prevFlights];
            newFlights[index] = updatedFlight;
            return newFlights;
          }
        }
        // If operation is not specified or UPDATE didn't find a matching flight, treat it as an INSERT
        console.log('Adding new flight (fallback):', updatedFlight);
        return [...prevFlights, updatedFlight];
      });

      // If we received data for an airline, remove it from pendingAirlines and clear its timeout
      const airline = updatedFlight.airline;
      if (airline && pendingAirlines[airline]) {
        setPendingAirlines((prev) => ({
          ...prev,
          [airline]: false,
        }));
        if (airlineTimeouts[airline]) {
          clearTimeout(airlineTimeouts[airline]);
          setAirlineTimeouts((prev) => {
            const { [airline]: _, ...rest } = prev;
            return rest;
          });
        }
      }
    });

    return () => {
      ApiClient.unsubscribeFromFlightUpdates();

      // Clear any remaining timeouts
      Object.values(airlineTimeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [pendingAirlines, airlineTimeouts]);

  return { flights, loading, error, fetchFlights, setError, pendingAirlines };
};

const FlightsPage: React.FC = () => {
  const { flights, loading, error, fetchFlights, setError, pendingAirlines } = useFlights();
  const defaultTheme = createTheme({ palette: { mode: "light" } });
  const location = useLocation();
  const [isSearching, setIsSearching] = useState(false);

  // Extract search params from location state
  const searchParams = location.state as {
    from: Airport | null;
    to: Airport | null;
    date: Dayjs | null;
  } | undefined;

  const handleSearch = useCallback(async (from: Airport | null, to: Airport | null, date: Dayjs | null) => {
    setIsSearching(true);
    try {
      if (!from || !to || !date) {
        console.log('Search not performed due to missing parameters');
        return;
      }
      await fetchFlights(from.iataCode, to.iataCode, date.toDate());
    } catch (error) {
      console.error("Error fetching flights:", error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setIsSearching(false);
    }
  }, [fetchFlights, setError]);

  const renderContent = (flights: Flight[]) => {
    if (loading && flights.length === 0) return <LoadingSpinner />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    const flightsByAirline = flights.reduce((acc, flight) => {
      if (!acc[flight.airline]) {
        acc[flight.airline] = [];
      }
      acc[flight.airline].push(flight);
      return acc;
    }, {} as Record<string, Flight[]>);

    const allAirlines = ['united', 'delta', 'american', 'alaska'];

    // Separate airlines with data and pending airlines
    const airlinesWithData = allAirlines.filter((airline) => flightsByAirline[airline]);
    const pendingAirlinesList = allAirlines.filter(
      (airline) => !flightsByAirline[airline] && pendingAirlines[airline]
    );

    // If no airlines have data and no airlines are pending, show the message
    if (airlinesWithData.length === 0 && pendingAirlinesList.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Typography variant="h6" sx={{ color: '#012330' }}>
            We couldn't find any flights for your chosen route.
          </Typography>
        </Box>
      );
    }

    return (
      <>
        {/* Airlines with data */}
        {airlinesWithData.map((airline) => {
          const airlineFlights = flightsByAirline[airline];
          const minPoints = Math.min(...airlineFlights.map(f => f.points));

          return (
            <AirlineFlightCard
              key={airline}
              airlineName={airline}
              pointsRequired={minPoints}
              isExpandedByDefault={true}
            >
              <Grid container spacing={3}>
                {airlineFlights.map((flight) => (
                  <Grid item xs={12} sm={12} md={12} key={flight.id}>
                    <InlineFlightCard flight={flight} />
                  </Grid>
                ))}
              </Grid>
            </AirlineFlightCard>
          );
        })}

        {/* Ghost cards */}
        {pendingAirlinesList.map((airline) => (
          <GhostCard key={airline} />
        ))}
      </>
    );
  };

  useEffect(() => {
    if (searchParams?.from && searchParams?.to && searchParams?.date && flights.length === 0) {
      handleSearch(searchParams.from, searchParams.to, searchParams.date);
    }
  }, [searchParams, flights.length, handleSearch]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <LandingNavBar />
        <Box component="main" sx={{ flexGrow: 1, pt: 10 }}>
          <Container maxWidth="lg">
            <Box sx={{ mb: 4 }}>
              <FlightSearch 
                onSearch={handleSearch} 
                isLoading={isSearching} 
                isCompact={true}
                initialFrom={searchParams?.from || null}
                initialTo={searchParams?.to || null}
                initialDate={searchParams?.date || null}
              />
            </Box>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <LoadingSpinner />
              </Box>
            ) : error ? (
              <Typography color="error">Error: {error}</Typography>
            ) : (
              renderContent(flights)
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FlightsPage;
