import React from 'react';
import { Card, styled, Grid } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { Flight } from '../../ApiClient';
import { useState, useRef, useEffect } from 'react';
import { mapFareClass, calculateFlightDuration } from '../../utils/flightUtils';

interface InlineFlightCardProps {
  flight: Flight;
}

const keyframes = `
  @keyframes fadeInBlink {
    0% { opacity: 0; }
    50% { opacity: 1; }
     75% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const PointsDisplayBlink = styled('span')({
    animation: 'fadeInBlink 1s infinite',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#061D27',
    marginRight: 0,
    justifyContent: 'center',
    display: 'flex',
    transition: 'color 0.5s ease, transform 0.5s ease',
    
});

const PointsDisplay = styled('span')({
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#061D27',
    marginRight: 0,
    justifyContent: 'center',
    display: 'flex',
    transition: 'color 0.5s ease, transform 0.5s ease',
  });

const StyledCard = styled(Card)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    minWidth: "100%",
    height: "6.5rem",
    marginBottom: theme.spacing(1.5),
    background: '#ffffff',
    boxShadow: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#EDF4F7',
    }
}));

const generateAirlineUrl = (flight: Flight, formattedDate: string): string => {
    switch(flight.airline) {
        case 'alaska':
            const alaskaParams = new URLSearchParams({
                'A': '1',
                'O': flight.src,
                'D': flight.dest,
                'OD': formattedDate,
                'OT': 'Anytime',
                'RT': 'false',
                'UPG': 'none',
                'ShoppingMethod': 'onlineaward',
                'awardType': 'MilesOnly'
            });
            return `https://www.alaskaair.com/search/results?${alaskaParams}`;

        case 'american':
            const aaSlice = {
                orig: flight.src,
                origNearby: false,
                dest: flight.dest,
                destNearby: false,
                date: formattedDate
            };
            const aaParams = new URLSearchParams({
                'locale': 'en_US',
                'pax': '1',
                'adult': '1',
                'type': 'OneWay',
                'searchType': 'Award',
                'cabin': '',
                'carriers': 'ALL',
                'travelType': 'personal',
                'slices': JSON.stringify([aaSlice]),
                'pos': 'US',
                'ltier': 'Regular',
                'ttype': 'personal'
            });
            return `https://www.aa.com/booking/search?${aaParams}`;

        case 'delta':
            const deltaParams = new URLSearchParams({
                'cacheKeySuffix': 'T',
                'cartId': '',
                'departure': flight.src,
                'destination': flight.dest,
                'departureDate': formattedDate,
                'origin': flight.src,
                'paxCount': '1',
                'searchType': 'oneWay',
                'tripType': 'ONE_WAY'
            });
            return `https://www.delta.com/flight-search/book-a-flight?${deltaParams}`;

        default:
            const unitedParams = new URLSearchParams({
                'f': flight.src,
                't': flight.dest,
                'd': formattedDate,
                'sc': '7',
                'st': 'bestmatches',
                'tt': '1',
                'at': '1',
                'taxng': '1',
                'clm': '7',
                'tqp': 'A'
            });
            return `https://www.united.com/en/us/fsr/choose-flights?${unitedParams}`;
    }
};

const InlineFlightCard: React.FC<InlineFlightCardProps> = ({ flight }) => {
    const [displayPoints, setDisplayPoints] = useState<number | null>(flight.points);
    const [blinking, setBlinking] = useState(false);
    const [blinkCount, setBlinkCount] = useState(0);
    const prevPointsRef = useRef(flight.points);
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        
        const date = new Date(flight.takeOffDateTime);
        const formattedDate = date.toLocaleDateString('en-CA');
        
        const url = generateAirlineUrl(flight, formattedDate);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    useEffect(() => {
        if (flight.points !== prevPointsRef.current) {
          setDisplayPoints(flight.points);
          setBlinkCount(0);
          setBlinking(true);
          prevPointsRef.current = flight.points;
        }
    }, [flight.points]);

    useEffect(() => {
        if (blinking) {
          const blinkInterval = setInterval(() => {
            // setBlinking((prev) => !prev);
            setBlinkCount((prev) => prev + 1);
          }, 1000); // Adjust the interval for the desired blink speed
    
          if (blinkCount >= 1) {
            clearInterval(blinkInterval);
            setBlinking(false);
          }
    
          return () => clearInterval(blinkInterval);
        }
    }, [blinking, blinkCount]);

    const formatTime = (date: Date | string | null) => {
        if (!date) return 'N/A';
        const dateObject = date instanceof Date ? date : new Date(date);
        if (isNaN(dateObject.getTime())) return 'N/A';
        return dateObject.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const textStyle = {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#061D27',
    };

    const lineStyle = {
        width: '70%',
        backgroundColor: 'grey',
        height: '1px',
        margin: '0.5rem auto',
        background: 'linear-gradient(to right, grey 20%, lightgrey 20%, lightgrey 80%, grey 80%)',
    };

    // Add this function to safely format points
    const formatPoints = (points: number | null) => {
        return points != null ? points.toLocaleString() : 'N/A';
    };

    return (
        <StyledCard 
            className="inline-flight-card" 
            onClick={handleClick}
        >
            <style>{keyframes}</style>
            <div style={{ display: 'flex' , width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            </div>
            <Grid container style={{ height: '100%' }}>
                {/* Origin Flight Time and Airport*/}
                <Grid
                item
                style={{
                    ...textStyle,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingLeft: '4rem',
                    width: '14%',
                }}
                >
                    <span style={{ 
                        fontSize: '1.5rem', 
                        fontWeight: 600, 
                        color: '#061D27',
                        whiteSpace: 'nowrap'
                    }}>
                        {formatTime(flight.takeOffDateTime)}
                    </span>
                </Grid>

                {/* Flight Duration Line Graphic */}
                <Grid
                item
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '45%',
                    flexDirection: 'column',
                }}
                >
                    <div style={{ display: 'flex', width: '70%', justifyContent: 'space-between' }}>
                        <FlightTakeoffIcon />
                        <span style={{ fontSize: '0.8rem', marginBottom: '0.2rem', color: '#010F16' }}>
                            {calculateFlightDuration(flight.takeOffDateTime, flight.landingDateTime)}
                        </span>
                        <FlightLandIcon />
                    </div>
                    <div style={lineStyle}></div>
                    <div style={{ display: 'flex', width: '70%', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: '0.8rem', color: '#010F16', marginBottom: '0.2rem' }}>
                            {flight.src}
                        </span>
                        <span style={{ fontSize: '0.8rem', color: '#010F16', marginBottom: '0.2rem' }}>
                            {flight.dest}
                        </span>
                    </div>
                </Grid>

                {/* Destination Flight Time and Airport*/}
                <Grid
                item
                style={{
                    ...textStyle,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    paddingRight: '4rem',
                    width: '14%',
                }}
                >
                    <span style={{ 
                        fontSize: '1.5rem', 
                        fontWeight: 600, 
                        color: '#061D27',
                        whiteSpace: 'nowrap'
                    }}>
                        {formatTime(flight.landingDateTime)}
                    </span>
                </Grid>

                {/* Points and Fare Class*/}
                <Grid
                    item
                    style={{
                        ...textStyle,
                        textAlign: 'left',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        width: '20%',
                    }}
                    >
                    <div style={{ 
                        marginLeft: '2rem', 
                        height: '80%', 
                        width: '1px', 
                        backgroundColor: 'rgba(6, 29, 39, 0.5)', // #061D27 with 50% opacity
                    }} />
                    <div style={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                         {blinking ? (
                <PointsDisplayBlink>
                  {formatPoints(displayPoints)}
                </PointsDisplayBlink>
              ) : (
                <PointsDisplay>
                  {formatPoints(displayPoints)}
                </PointsDisplay>
              )}
                        <span style={{ 
                            fontSize: '1rem', 
                            fontWeight: 400, 
                            color: '#061D27', 
                            marginRight: 0, 
                            justifyContent: 'center', 
                            display: 'flex' 
                        }}>
                            {flight.fareClass ? mapFareClass(flight.fareClass) : 'N/A'}
                        </span>
                    </div>
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default InlineFlightCard;
