import * as React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

interface HeroProps {
  showWaitlistContent?: boolean;
}

export default function Hero({ showWaitlistContent = true }: HeroProps) {
  const handleJoinWaitlist = () => {
    const email = 'admin@flightfinder.cc';
    const subject = 'Request to Join Flight Finder Waitlist';
    const body = 'Hello Flight Finder team,\n\nI would like to join the waitlist for early access to Flight Finder. Please add me to your early access community.\n\nThank you!';

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        pt: { xs: 15, sm: 18, md: 20 },
        pb: { xs: 2, sm: 4 },
        minHeight: showWaitlistContent ? "100vh" : "auto",
      }}
    >
      <Stack
        spacing={4}
        useFlexGap
        sx={{ width: { xs: "100%", sm: "80%", md: "70%" }, alignItems: "center" }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            textAlign: "center",
            fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4rem" },
            lineHeight: 1.2,
            color: "#f0f0f0",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Award travel made intuitive and effortless
        </Typography>
        {showWaitlistContent && (
          <>
            <Box
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                borderRadius: '20px',
                padding: '8px 16px',
                backdropFilter: 'blur(8px)',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  textAlign: "center",
                  fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
                  color: '#f0f0f0',
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
                }}
              >
                Coming soon to our early access community.
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={handleJoinWaitlist}
              sx={{
                backgroundColor: "#010F16",
                fontFamily: 'Montserrat, sans-serif',
                fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
                padding: "12px 24px",
                width: { xs: "100%", sm: "auto" },
                minWidth: { sm: "200px" },
                mt: 4,
                '&:hover': {
                  backgroundColor: "#0B5369",
                }
              }}
            >
              JOIN WAITLIST
            </Button>
          </>
        )}
      </Stack>
    </Container>
  );
}
