import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Google as GoogleIcon } from "@mui/icons-material";
import LandingNavBar from "../components/landing/LandingNavBar";
import serverUrl from "../config";
import { UserContext } from "../contexts/UserContext";
import { Navigate } from "react-router-dom";

export default function LoginPage() {
  const { user } = React.useContext(UserContext);

  if (user) {
    return <Navigate to="/" replace />;
  }

  const defaultTheme = createTheme({ palette: { mode: "light" } });

  const handleGoogleLogin = () => {
    window.location.href = `${serverUrl}/api/auth/google`;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <LandingNavBar />

      <Box
        sx={{
          width: "100%",
          backgroundImage: "linear-gradient(180deg, #CEE5FD, #FFF)",
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <CssBaseline />
        <Container maxWidth="sm">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Card>
              <CardContent>
                <Box textAlign="center" marginBottom={2}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    Welcome to Flight Finder
                  </Typography>
                  <Typography variant="body1" component="p">
                    Sign in to continue to your account
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleLogin}
                  sx={{
                    backgroundColor: "#010F16",
                    color: "#FFFFFF",
                    '&:hover': {
                      backgroundColor: "#0B5369",
                    },
                  }}
                >
                  Continue With Google
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
