export const calculateFlightDuration = (takeOffDateTime: Date, landingDateTime: Date | null): string | null => {
  if (!landingDateTime) return null;
  const durationInMinutes = (new Date(landingDateTime).getTime() - new Date(takeOffDateTime).getTime()) / 60000;
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.round(durationInMinutes % 60);
  
  if (hours === 0) {
    return `${minutes} min`;
  } else if (minutes === 0) {
    return `${hours} h`;
  } else {
    return `${hours} h ${minutes} min`;
  }
};

export const mapFareClass = (fareClass: string | undefined | null): string => {
  if (!fareClass) return 'N/A';
  const fareClassMap: { [key: string]: string } = {
    'Y': 'Economy',
    'J': 'Business',
    'F': 'First Class',
    'W': 'Premium Economy',
  };
  return fareClassMap[fareClass.toUpperCase()] || fareClass;
};

export const getNormalizedAirlineName = (airline: string): string => {
  const airlineNameMap: { [key: string]: string } = {
    'delta': 'Delta',
    'american': 'American',
    'united': 'United',
    'alaska': 'Alaska',
  };
  return airlineNameMap[airline] || airline;
};