import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../../assets/logo_transparent.png";
import { useState, useEffect, useContext, MouseEvent } from "react";
import { UserContext } from "../../contexts/UserContext";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout"; // Importing Logout Icon
import { ApiClient } from "../../ApiClient";
import { Link } from "react-router-dom"; // Add this import

const logoStyle = {
  width: "2.5rem",
  height: "auto",
  marginLeft: "0.5rem",
  filter: "drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.5))",
};

function LandingNavBar() {
  const [scrolling, setScrolling] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await ApiClient.logout();
      setUser(undefined);
    } catch (error) {
      console.error("Sign out failed:", error);
    }
    handleClose();
  };

  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
          
          .signUpButton {
            background-color: #010F16;
            font-family: 'Montserrat', sans-serif;
          }
          .signUpButton:hover {
            background-color: #0B5369;
          }

          .logInButton:hover {
            background-color: rgba(250, 250, 240, 0.50);
          }
          
          .logo-link {
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: opacity 0.3s ease;
          }
          .logo-link:hover {
            opacity: 0.8;
          }
        `}
      </style>
      <AppBar 
        position="fixed" 
        elevation={scrolling ? 4 : 0} 
        sx={{ 
          background: scrolling ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
          transition: 'background 0.6s ease, box-shadow 0.6s ease',
          backdropFilter: scrolling ? 'blur(10px)' : 'none',
          boxShadow: scrolling ? '0 4px 30px rgba(0, 0, 0, 0.1)' : 'none',
          pt: { xs: 1, sm: 2, md: 1.5 },
          pb: { xs: 1, sm: 2, md: 1.5 }
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 1, sm: 2, md: 4 }, py: { xs: 1, sm: 1.5, md: 2 } }}>
            <Link to="/" className="logo-link">
              <Box sx={{ display: "flex", alignItems: "center", ml: { xs: -1, sm: -2, md: -3 } }}>
                <img src={logo} style={logoStyle} alt="Logo of Flight Finder" />
                <Typography 
                  color="#010F16" 
                  variant="h6" 
                  component="div" 
                  sx={{ 
                    fontFamily: 'Montserrat, sans-serif', 
                    fontWeight: "bold", 
                    fontSize: { xs: '1.3rem', sm: '1.5rem', md: '1.8rem' },
                    ml: 2 
                  }}
                >
                  Flight Finder
                </Typography>
              </Box>
            </Link>
        
            <Box sx={{ mr: { xs: -1, sm: -2, md: -3 } }}>
              {user ? (
                <>
                  <Avatar
                    alt={`${user.firstName} ${user.lastName}`}
                    src={user.profilePhoto}
                    onClick={handleMenu}
                    sx={{ 
                      cursor: 'pointer',
                      width: { xs: 32, sm: 40 },
                      height: { xs: 32, sm: 40 },
                    }}
                  />
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    aria-labelledby="user-menu"
                    disableScrollLock={true}
                  >
                    {/* User Information Section */}
                    <Box sx={{ px: 2, py: 1 }}>
                      <Typography variant="body1" fontWeight="bold">
                        {user.firstName} {user.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {user.email}
                      </Typography>
                    </Box>
                    <Divider />

                    {/* Sign Out Option with Icon */}
                    <MenuItem onClick={handleSignOut}>
                      <LogoutIcon sx={{ marginRight: 1 }} /> Log out
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  {/* Buttons removed */}
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default LandingNavBar;
