import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { getAirlineLogo, getAirlineRewardsProgram } from '../../utils/logoUtils';
import { getAirlineCreditCards } from '../../utils/creditCardUtils';
import { getNormalizedAirlineName } from '../../utils/flightUtils';

const keyframes = `
  @keyframes fadeInBlink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    75% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const StyledCard = styled(Card)({
  width: '100%',
  margin: '16px 0',
  borderRadius: '8px',
  boxShadow: 'none',
  border: '1px solid rgba(207, 229, 236, 0.8)',
});

const StyledCardContent = styled(CardContent)({
  padding: '16px',
  '&:last-child': {
    paddingBottom: '16px', // Override MUI's default last-child padding
  },
});

const AirlineLogo = styled('img')({
  width: '60px',
  height: '60px',
  objectFit: 'contain',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const RewardsProgramLogo = styled('img')(({ src = '' }) => ({
  objectFit: 'contain',
  // Custom widths for specific airlines
  ...(src.includes('american') && {
    width: '130px',
  }),
  ...((src.includes('united') || src.includes('delta')) && {
    width: '110px',
  }),
  ...(src.includes('alaska') && {
    width: '90px',
  }),
}));

const AirlineName = styled(Typography)({
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  fontSize: '1.5rem',
  color: '#010F16',
  textAlign: 'left',
});

const PointsDisplayBlink = styled('span')({
  animation: 'fadeInBlink 1s infinite',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
  color: '#061D28',
  textAlign: 'right',
  transition: 'color 0.5s ease, transform 0.5s ease',
  
});

const PointsDisplay = styled(Typography)({
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  fontSize: '1.8rem',
  color: '#061D28',
  textAlign: 'right',
});

const CreditCardContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  justifyContent: 'flex-end',
});

const CreditCardLogo = styled('div')<{ index: number }>(({ index }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  marginLeft: index === 0 ? '0' : '-10px', // Negative margin for overlap
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  overflow: 'hidden',
  border: '0.5px solid #0B5369',
  zIndex: 10 - index,
}));

const CreditCardImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const MoreCreditCards = styled('div')({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#061D27',
  cursor: 'pointer',
  marginLeft: '-10px',
  zIndex: 5,
  border: '0.5px solid #0B5369',
});

const RotatingExpandIcon = styled(IconButton)<{ $isExpanded: boolean }>(
  ({ $isExpanded }) => ({
    color: '#010F16',
    transition: 'transform 0.3s ease-in-out, color 0.3s ease-in-out',
    transform: $isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
    padding: 0,
    minWidth: 'auto',
    borderRadius: 0,
  })
);

const TopSection = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  margin: '-16px',  // Negative margin to counter the CardContent padding
  padding: '16px',  // Add padding back to maintain spacing
  '&:hover': {
    backgroundColor: '#EDF4F7',  // Updated to match InlineFlightCard
  },
}));
const ArrowContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
  },
});

const PointsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: '16px',
});

const CenterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  gap: '10px',
  marginLeft: '-50px',
});

interface AirlineFlightCardProps {
  airlineName: string;
  pointsRequired: number;
  isExpandedByDefault?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  children?: React.ReactNode;
}

const AirlineFlightCard: React.FC<AirlineFlightCardProps> = ({
  airlineName,
  pointsRequired,
  onExpand,
  onCollapse,
  isExpandedByDefault,
  children
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault || false);
  const [blinking, setBlinking] = useState(false);
  const [blinkCount, setBlinkCount] = useState(0);
  const [displayPointsRequired, setDisplayPointsRequired] = useState(pointsRequired);
  const prevPointsRequiredRef = useRef(pointsRequired);

  const creditCards = getAirlineCreditCards(airlineName);

  useEffect(() => {
    if (pointsRequired !== prevPointsRequiredRef.current) {
      setDisplayPointsRequired(pointsRequired);
      setBlinkCount(0);
      setBlinking(true);
      prevPointsRequiredRef.current = pointsRequired;
    }
  }, [pointsRequired]);

  useEffect(() => {
    if (blinking) {
      const blinkInterval = setInterval(() => {
        setBlinkCount((prev) => prev + 1);
      }, 1000);

      if (blinkCount >= 1) {
        clearInterval(blinkInterval);
        setBlinking(false);
      }

      return () => clearInterval(blinkInterval);
    }
  }, [blinking, blinkCount]);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
    if (!isExpanded && onExpand) onExpand();
    if (isExpanded && onCollapse) onCollapse();
  };

  const formatPoints = (points: number) => {
    const pointsInK = Math.floor(points / 1000);
    return `${pointsInK}K`;
  };

  const displayedCards = creditCards.slice(0, 3);
  const hiddenCards = creditCards.slice(3);

  return (
    <StyledCard elevation={0} sx={{ cursor: 'default' }}>
      <style>{keyframes}</style>
      <StyledCardContent>
        {!isExpanded ? (
          // When collapsed, wrap the entire content in TopSection
          <TopSection onClick={handleToggle}>
            <Grid container alignItems="center" spacing={2}>
              {/* Airline Logo */}
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <AirlineLogo src={getAirlineLogo(airlineName)} alt={`${airlineName} Logo`} />
              </Grid>

              {/* Airline Name */}
              <Grid 
                item 
                xs 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'flex-start' 
                }}
              >
                <AirlineName variant="h5">
                  {getNormalizedAirlineName(airlineName)} Airlines
                </AirlineName>
              </Grid>

              {/* Points Required, Credit Cards, and Rewards Program */}
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <CenterContainer>
                    <CreditCardContainer>
                      {displayedCards.map((card, index) => (
                        <Tooltip key={index} title={card.name}>
                          <CreditCardLogo index={index}>
                            <CreditCardImage src={card.logo} alt={card.name} />
                          </CreditCardLogo>
                        </Tooltip>
                      ))}
                      {hiddenCards.length > 0 && (
                        <Tooltip title={hiddenCards.map(card => card.name).join(', ')}>
                          <MoreCreditCards>+{hiddenCards.length}</MoreCreditCards>
                        </Tooltip>
                      )}
                    </CreditCardContainer>
                    <ArrowContainer>
                      <ArrowRightAltIcon />
                    </ArrowContainer>
                    <Tooltip title={`${getNormalizedAirlineName(airlineName)} Rewards Program`}>
                      <RewardsProgramLogo 
                        src={getAirlineRewardsProgram(airlineName)} 
                        alt={`${getNormalizedAirlineName(airlineName)} Rewards Program`}
                      />
                    </Tooltip>
                  </CenterContainer>
                  <PointsContainer>
                    {blinking ?
                      <PointsDisplayBlink>
                        {formatPoints(displayPointsRequired)}+
                      </PointsDisplayBlink> :
                      <PointsDisplay>
                        {formatPoints(displayPointsRequired)}+
                      </PointsDisplay>
                    }
                  </PointsContainer>
                </Box>
              </Grid>

              {/* Expand/Collapse Icon */}
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <RotatingExpandIcon
                  $isExpanded={isExpanded}
                  aria-label={isExpanded ? 'Collapse' : 'Expand'}
                  disableRipple
                  onClick={handleToggle}
                >
                  <ExpandMoreIcon />
                </RotatingExpandIcon>
              </Grid>
            </Grid>
          </TopSection>
        ) : (
          // When expanded, show content
          <>
            <TopSection onClick={handleToggle}>
              <Grid container alignItems="center" spacing={2}>
                {/* Airline Logo */}
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <AirlineLogo src={getAirlineLogo(airlineName)} alt={`${airlineName} Logo`} />
                </Grid>

                {/* Airline Name */}
                <Grid 
                  item 
                  xs 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start' 
                  }}
                >
                  <AirlineName variant="h5">
                    {getNormalizedAirlineName(airlineName)} Airlines
                  </AirlineName>
                </Grid>

                {/* Points Required, Credit Cards, and Rewards Program */}
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <CenterContainer>
                      <CreditCardContainer>
                        {displayedCards.map((card, index) => (
                          <Tooltip key={index} title={card.name}>
                            <CreditCardLogo index={index}>
                              <CreditCardImage src={card.logo} alt={card.name} />
                            </CreditCardLogo>
                          </Tooltip>
                        ))}
                        {hiddenCards.length > 0 && (
                          <Tooltip title={hiddenCards.map(card => card.name).join(', ')}>
                            <MoreCreditCards>+{hiddenCards.length}</MoreCreditCards>
                          </Tooltip>
                        )}
                      </CreditCardContainer>
                      <ArrowContainer>
                        <ArrowRightAltIcon />
                      </ArrowContainer>
                      <Tooltip title={`${getNormalizedAirlineName(airlineName)} Rewards Program`}>
                        <RewardsProgramLogo 
                          src={getAirlineRewardsProgram(airlineName)} 
                          alt={`${airlineName} Rewards Program`}
                        />
                      </Tooltip>
                    </CenterContainer>
                    <PointsContainer>
                      {blinking ?
                        <PointsDisplayBlink>
                          {formatPoints(displayPointsRequired)}+
                        </PointsDisplayBlink> :
                        <PointsDisplay>
                          {formatPoints(displayPointsRequired)}+
                        </PointsDisplay>
                      }
                    </PointsContainer>
                  </Box>
                </Grid>

                {/* Expand/Collapse Icon */}
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <RotatingExpandIcon
                    $isExpanded={isExpanded}
                    aria-label={isExpanded ? 'Collapse' : 'Expand'}
                    disableRipple
                    onClick={handleToggle}
                  >
                    <ExpandMoreIcon />
                  </RotatingExpandIcon>
                </Grid>
              </Grid>
            </TopSection>
            <Box mt={2}>
              {children}
            </Box>
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default AirlineFlightCard;
