import * as React from "react";
import "./App.css";
import { Outlet } from "react-router";
import UserProvider from "./contexts/UserContext";
import { ApiClient } from "./ApiClient";

function App() {
  ApiClient.initializeSocket();
  return (
    <div className="App">
      <UserProvider>
        <Outlet />
      </UserProvider>
    </div>
  );
}

export default App;
