import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

export default function PrivateRoute() {
  const { user } = React.useContext(UserContext);

  if (user) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" replace />;
  }
}
