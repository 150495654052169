import React from 'react';
import { Box, keyframes } from '@mui/material';

const skChase = keyframes`
  100% { transform: rotate(360deg); }
`;

const skChaseDot = keyframes`
  80%, 100% { transform: rotate(360deg); }
`;

const skChaseDotBefore = keyframes`
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1.0);
  }
`;

const LoadingSpinner: React.FC = () => {
  return (
    <Box
      sx={{
        width: '30px',
        height: '30px',
        position: 'relative',
        animation: `${skChase} 2.5s infinite linear both`,
      }}
    >
      {[...Array(6)].map((_, index) => (
        <Box
          key={index}
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            animation: `${skChaseDot} 2.0s infinite ease-in-out both`,
            animationDelay: `${-1.1 + index * 0.1}s`,
            '&::before': {
              content: '""',
              display: 'block',
              width: '25%',
              height: '25%',
              backgroundColor: '#fff',
              borderRadius: '100%',
              animation: `${skChaseDotBefore} 2.0s infinite ease-in-out both`,
              animationDelay: `${-1.1 + index * 0.1}s`,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default LoadingSpinner;