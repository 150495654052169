// Airline logos
import deltaLogo from '../assets/airline_logos/delta.png';
import americanLogo from '../assets/airline_logos/american.png';
import unitedLogo from '../assets/airline_logos/united.png';
import alaskaLogo from '../assets/airline_logos/alaska.png';

// Airline rewards programs
import deltaSkyMiles from '../assets/airline_rewards/delta.png';
import unitedMiles from '../assets/airline_rewards/united.png';
import alaskaMiles from '../assets/airline_rewards/alaska.png';
import americanAdvantage from '../assets/airline_rewards/american.png';

export const getAirlineLogo = (airlineName: string) => {
    switch (airlineName) {
        case 'delta':
            return deltaLogo;
        case 'american':
            return americanLogo;
        case 'united':
            return unitedLogo;
        case 'alaska':
            return alaskaLogo;
        default:
            return '';
    }
};

export const getAirlineRewardsProgram = (airlineName: string) => {
    switch (airlineName) {
        case 'delta':
            return deltaSkyMiles;
        case 'united':
            return unitedMiles;
        case 'alaska':
            return alaskaMiles;
        case 'american':
            return americanAdvantage;
    }
};
